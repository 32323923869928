import {Configuration} from '@/api';
import {toApiOrderCreate, toOrder, toOrders} from '@/api/mappers/mapOrder';
import {
    AcceptOrderRequest,
    OrdersApi as ApiOrdersApi,
    BulkCreateOrdersRequest,
    CancelOrderRequest,
    CompleteOrderRequest,
    CreateOrderRequest,
    DispatchOrderRequest,
    GetOrderDetailsRequest,
    ListOrdersRequest,
    RejectOrderRequest,
    SubmitOrderRequest,
    UpdateOrderRequest,
} from '@/gen/api';
import {GoodsReceivedNote} from '@/models/GoodsReceivedNote';
import {
    Order,
    OrderAssociation,
    OrderCreate,
    OrderCreateResult,
    Orders,
    OrderUpdate,
} from '@/models/Order';
import {TimeWindow} from '@/models/TimeWindow';

export interface OrderFilters {
    supplierId?: string;
    status?: string;
    period?: TimeWindow;
}

interface OrdersApi {
    listOrders(offset?: number, limit?: number, include?: OrderAssociation[]): Promise<Orders>;
    findOrders(
        query?: string | null,
        filters?: OrderFilters,
        offset?: number,
        limit?: number,
        include?: OrderAssociation[],
    ): Promise<Orders>;
    getOrder(idOrCode: string, include?: OrderAssociation[]): Promise<Order | undefined>;
    bulkCreateOrders(orders: OrderCreate[]): Promise<OrderCreateResult[]>;
    createOrder(order: OrderCreate): Promise<OrderCreateResult>;
    updateOrder(id: string, order: OrderUpdate): Promise<void>;
    submitOrder(id: string): Promise<void>;
    acceptOrder(id: string): Promise<void>;
    dispatchOrder(id: string): Promise<void>;
    rejectOrder(id: string, reason: string): Promise<void>;
    cancelOrder(id: string): Promise<void>;
    completeOrder(id: string): Promise<void>;
    addProofOfPayment(id: string, image: string): Promise<void>;
    submitGoodsReceivedNote(id: string, goodsReceivedNote: GoodsReceivedNote): Promise<void>;
}

export class OrdersApiImpl implements OrdersApi {
    private ordersApi: ApiOrdersApi;

    constructor(configuration: Configuration) {
        this.ordersApi = new ApiOrdersApi(configuration);
    }

    async listOrders(offset?: number, limit?: number, include?: OrderAssociation[]): Promise<Orders> {
        const requestParams: ListOrdersRequest = {
            offset,
            limit,
            include: include?.map(value => value.toString()),
        };

        try {
            const response = await this.ordersApi.listOrders(requestParams);
            return toOrders(response);
        } catch (error) {
            console.error('Error listing orders:', error);
            throw error;
        }
    }

    async findOrders(
        query?: string | null,
        filters?: OrderFilters,
        offset?: number,
        limit?: number,
        include?: OrderAssociation[],
    ): Promise<Orders> {
        const requestParams: ListOrdersRequest = {
            query: query || '',
            offset,
            limit,
            include: include?.map(value => value.toString()),
            ...filters,
        };

        try {
            const response = await this.ordersApi.listOrders(requestParams);
            return toOrders(response);
        } catch (error) {
            console.error('Error finding orders:', error);
            throw error;
        }
    }

    async getOrder(idOrCode: string, include?: OrderAssociation[]): Promise<Order | undefined> {
        try {
            const requestParameters: GetOrderDetailsRequest = {
                id: idOrCode,
                include: include?.map(value => value.toString()),
            };

            const response = await this.ordersApi.getOrderDetails(requestParameters);
            return toOrder(response);
        } catch (error) {
            console.error(`Failed to get order with id ${idOrCode}:`, error);
            return undefined;
        }
    }

    async createOrder(order: OrderCreate): Promise<OrderCreateResult> {
        try {
            const requestParameters: CreateOrderRequest = {
                orderCreateRequest: toApiOrderCreate(order),
            };

            return await this.ordersApi.createOrder(requestParameters);
        } catch (error) {
            console.error('Failed to create order:', error);
            throw error;
        }
    }

    async bulkCreateOrders(orders: OrderCreate[]): Promise<OrderCreateResult[]> {
        try {
            const bulkOrderCreateRequest: BulkCreateOrdersRequest = {
                bulkOrderCreateRequest: {
                    orders: orders.map(toApiOrderCreate),
                },
            };

            const response =
                await this.ordersApi.bulkCreateOrders(bulkOrderCreateRequest);
            return response.orders;
        } catch (error) {
            console.error('Failed to create bulk orders:', error);
            throw error;
        }
    }

    async updateOrder(id: string, order: OrderUpdate): Promise<void> {
        try {
            const requestParameters: UpdateOrderRequest = {
                id,
                orderUpdateRequest: {
                    confirmedDeliveryWindow: order.confirmedDeliveryWindow,
                    items: order.items,
                },
            };

            await this.ordersApi.updateOrder(requestParameters);
        } catch (error) {
            console.error('Failed to update order:', error);
            throw error;
        }
    }

    async submitOrder(id: string): Promise<void> {
        try {
            const requestParameters: SubmitOrderRequest = {id};

            await this.ordersApi.submitOrder(requestParameters);
        } catch (error) {
            console.error('Failed to submit order:', error);
            throw error;
        }
    }

    async acceptOrder(id: string): Promise<void> {
        try {
            const requestParameters: AcceptOrderRequest = {id};

            await this.ordersApi.acceptOrder(requestParameters);
        } catch (error) {
            console.error('Failed to accept order:', error);
            throw error;
        }
    }

    async dispatchOrder(id: string): Promise<void> {
        try {
            const requestParameters: DispatchOrderRequest = {id};

            await this.ordersApi.dispatchOrder(requestParameters);
        } catch (error) {
            console.error('Failed to dispatch order:', error);
            throw error;
        }
    }

    async rejectOrder(id: string, reason: string): Promise<void> {
        try {
            const requestParameters: RejectOrderRequest = {
                id,
                orderReject: {reason},
            };

            await this.ordersApi.rejectOrder(requestParameters);
        } catch (error) {
            console.error('Failed to reject order:', error);
            throw error;
        }
    }

    async cancelOrder(id: string): Promise<void> {
        try {
            const requestParameters: CancelOrderRequest = {id};

            await this.ordersApi.cancelOrder(requestParameters);
        } catch (error) {
            console.error('Failed to cancel order:', error);
            throw error;
        }
    }

    async completeOrder(id: string): Promise<void> {
        try {
            const requestParameters: CompleteOrderRequest = {id};

            await this.ordersApi.completeOrder(requestParameters);
        } catch (error) {
            console.error('Failed to complete order:', error);
            throw error;
        }
    }

    addProofOfPayment(_id: string, _image: string): Promise<void> {
        return Promise.resolve(undefined);
    }

    submitGoodsReceivedNote(_id: string, _goodsReceivedNote: GoodsReceivedNote): Promise<void> {
        return Promise.resolve(undefined);
    }
}

export default OrdersApi;
