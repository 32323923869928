/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import * as runtime from '../runtime';
// @ts-ignore
import type {
// @ts-ignore
  AddProofOfPaymentRequest,
// @ts-ignore
  BulkOrderCreateRequest,
// @ts-ignore
  BulkOrderCreateResponse,
// @ts-ignore
  GoodsReceivedNote,
// @ts-ignore
  Order,
// @ts-ignore
  OrderCreateRequest,
// @ts-ignore
  OrderCreateResult,
// @ts-ignore
  OrderListResponse,
// @ts-ignore
  OrderReject,
// @ts-ignore
  OrderUpdateRequest,
} from '../models/index';
// @ts-ignore
import {
// @ts-ignore
    AddProofOfPaymentRequestFromJSON,
// @ts-ignore
    AddProofOfPaymentRequestToJSON,
// @ts-ignore
    BulkOrderCreateRequestFromJSON,
// @ts-ignore
    BulkOrderCreateRequestToJSON,
// @ts-ignore
    BulkOrderCreateResponseFromJSON,
// @ts-ignore
    BulkOrderCreateResponseToJSON,
// @ts-ignore
    GoodsReceivedNoteFromJSON,
// @ts-ignore
    GoodsReceivedNoteToJSON,
// @ts-ignore
    OrderFromJSON,
// @ts-ignore
    OrderToJSON,
// @ts-ignore
    OrderCreateRequestFromJSON,
// @ts-ignore
    OrderCreateRequestToJSON,
// @ts-ignore
    OrderCreateResultFromJSON,
// @ts-ignore
    OrderCreateResultToJSON,
// @ts-ignore
    OrderListResponseFromJSON,
// @ts-ignore
    OrderListResponseToJSON,
// @ts-ignore
    OrderRejectFromJSON,
// @ts-ignore
    OrderRejectToJSON,
// @ts-ignore
    OrderUpdateRequestFromJSON,
// @ts-ignore
    OrderUpdateRequestToJSON,
} from '../models/index';

export interface AcceptOrderRequest {
    id: string;
}

export interface AddProofOfPaymentOperationRequest {
    id: string;
    addProofOfPaymentRequest: AddProofOfPaymentRequest;
}

export interface BulkCreateOrdersRequest {
    bulkOrderCreateRequest: BulkOrderCreateRequest;
}

export interface CancelOrderRequest {
    id: string;
}

export interface CompleteOrderRequest {
    id: string;
}

export interface CreateOrderRequest {
    orderCreateRequest?: OrderCreateRequest;
}

export interface DispatchOrderRequest {
    id: string;
}

export interface GetOrderDetailsRequest {
    id: string;
    include?: Array<string>;
    limit?: number;
    offset?: number;
}

export interface ListOrdersRequest {
    query?: string;
    include?: Array<string>;
    limit?: number;
    offset?: number;
    orderId?: Array<string>;
    supplierId?: string;
}

export interface RejectOrderRequest {
    id: string;
    orderReject?: OrderReject;
}

export interface SubmitGoodsReceivedNoteRequest {
    id: string;
    goodsReceivedNote: GoodsReceivedNote;
}

export interface SubmitOrderRequest {
    id: string;
}

export interface UpdateOrderRequest {
    id: string;
    orderUpdateRequest?: OrderUpdateRequest;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     * Accept an order
     */
    async acceptOrderRaw(requestParameters: AcceptOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acceptOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept an order
     */
    async acceptOrder(requestParameters: AcceptOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Add a proof of payment for an order
     */
    async addProofOfPaymentRaw(requestParameters: AddProofOfPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addProofOfPayment.');
        }

        if (requestParameters.addProofOfPaymentRequest === null || requestParameters.addProofOfPaymentRequest === undefined) {
            throw new runtime.RequiredError('addProofOfPaymentRequest','Required parameter requestParameters.addProofOfPaymentRequest was null or undefined when calling addProofOfPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/{id}/proof-of-payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProofOfPaymentRequestToJSON(requestParameters.addProofOfPaymentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a proof of payment for an order
     */
    async addProofOfPayment(requestParameters: AddProofOfPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProofOfPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Create multiple orders in bulk
     */
    async bulkCreateOrdersRaw(requestParameters: BulkCreateOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOrderCreateResponse>> {
        if (requestParameters.bulkOrderCreateRequest === null || requestParameters.bulkOrderCreateRequest === undefined) {
            throw new runtime.RequiredError('bulkOrderCreateRequest','Required parameter requestParameters.bulkOrderCreateRequest was null or undefined when calling bulkCreateOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkOrderCreateRequestToJSON(requestParameters.bulkOrderCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOrderCreateResponseFromJSON(jsonValue));
    }

    /**
     * Create multiple orders in bulk
     */
    async bulkCreateOrders(requestParameters: BulkCreateOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOrderCreateResponse> {
        const response = await this.bulkCreateOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel an order
     */
    async cancelOrderRaw(requestParameters: CancelOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel an order
     */
    async cancelOrder(requestParameters: CancelOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Complete an order
     */
    async completeOrderRaw(requestParameters: CompleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete an order
     */
    async completeOrder(requestParameters: CompleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.completeOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new order
     */
    async createOrderRaw(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderCreateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderCreateRequestToJSON(requestParameters.orderCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderCreateResultFromJSON(jsonValue));
    }

    /**
     * Create a new order
     */
    async createOrder(requestParameters: CreateOrderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderCreateResult> {
        const response = await this.createOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Dispatch an order
     */
    async dispatchOrderRaw(requestParameters: DispatchOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dispatchOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}/dispatch`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dispatch an order
     */
    async dispatchOrder(requestParameters: DispatchOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dispatchOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Get details of a specific order
     */
    async getOrderDetailsRaw(requestParameters: GetOrderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrderDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Get details of a specific order
     */
    async getOrderDetails(requestParameters: GetOrderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
        const response = await this.getOrderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of orders
     */
    async listOrdersRaw(requestParameters: ListOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.orderId) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderListResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of orders
     */
    async listOrders(requestParameters: ListOrdersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderListResponse> {
        const response = await this.listOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject an order
     */
    async rejectOrderRaw(requestParameters: RejectOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rejectOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRejectToJSON(requestParameters.orderReject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject an order
     */
    async rejectOrder(requestParameters: RejectOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Submit a goods received note (GRN) for an order
     */
    async submitGoodsReceivedNoteRaw(requestParameters: SubmitGoodsReceivedNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitGoodsReceivedNote.');
        }

        if (requestParameters.goodsReceivedNote === null || requestParameters.goodsReceivedNote === undefined) {
            throw new runtime.RequiredError('goodsReceivedNote','Required parameter requestParameters.goodsReceivedNote was null or undefined when calling submitGoodsReceivedNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/{id}/goods-received-note`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoodsReceivedNoteToJSON(requestParameters.goodsReceivedNote),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit a goods received note (GRN) for an order
     */
    async submitGoodsReceivedNote(requestParameters: SubmitGoodsReceivedNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitGoodsReceivedNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Submit an order
     */
    async submitOrderRaw(requestParameters: SubmitOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit an order
     */
    async submitOrder(requestParameters: SubmitOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing order
     */
    async updateOrderRaw(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateRequestToJSON(requestParameters.orderUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing order
     */
    async updateOrder(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderRaw(requestParameters, initOverrides);
    }

}
