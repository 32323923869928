/* tslint:disable */
/* eslint-disable */
/**
 * OMS API
 * The documentation for the Order Management System (OMS) backend API.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderStatus = {
    New: 'NEW',
    Accepted: 'ACCEPTED',
    Dispatched: 'DISPATCHED',
    Rejected: 'REJECTED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED',
    Submitted: 'SUBMITTED',
    Received: 'RECEIVED',
    Unknown: 'UNKNOWN'
} as const;
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


// @ts-ignore
export function OrderStatusFromJSON(json: any): OrderStatus {
    return OrderStatusFromJSONTyped(json, false);
}

// @ts-ignore
export function OrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatus {
    return json as OrderStatus;
}

// @ts-ignore
export function OrderStatusToJSON(value?: OrderStatus | null): any {
    return value as any;
}

