import {toStore} from '@/api/mappers/mapStore';
import {toSupplier} from '@/api/mappers/mapSupplier';
import {toWarehouse} from '@/api/mappers/mapWarehouse';
import {
    Order as ApiOrder,
    OrderCreate as ApiOrderCreate,
    OrderItemCreate as ApiOrderItemCreate,
    OrderListResponse as ApiOrderListResponse,
} from '@/gen/api';
import {OrderCreate, Order as OrderModel, Orders} from '@/models/Order';
import {OrderItemCreate} from '@/models/OrderItem';
import {OrderStatus} from '@/models/OrderStatus';

const parseOrderStatus = (status: string): OrderStatus => {
    switch (status.toUpperCase()) {
        case 'NEW':
            return OrderStatus.New;
        case 'ACCEPTED':
            return OrderStatus.Accepted;
        case 'DISPATCHED':
            return OrderStatus.Dispatched;
        case 'REJECTED':
            return OrderStatus.Rejected;
        case 'COMPLETED':
            return OrderStatus.Completed;
        case 'CANCELED':
            return OrderStatus.Canceled;
        case 'SUBMITTED':
            return OrderStatus.Submitted;
        case 'RECEIVED':
            return OrderStatus.Received;
        default:
            return OrderStatus.Unknown;
    }
};

export const toOrder = (apiOrder: ApiOrder): OrderModel => {
    return {
        ...apiOrder,
        items: apiOrder.items,
        status: parseOrderStatus(apiOrder.status),
        store: apiOrder.store && toStore(apiOrder.store),
        supplier: apiOrder.supplier ? toSupplier(apiOrder.supplier) : undefined,
        warehouse: apiOrder.warehouse && toWarehouse(apiOrder.warehouse),
    };
};

export const toOrders = (response: ApiOrderListResponse): Orders => {
    return {
        items: response.items.map(toOrder),
        totalCount: response.totalCount,
        estimatedTotalCount: response.estimatedTotalCount,
    };
};

const toApiOrderItemCreate = (item: OrderItemCreate): ApiOrderItemCreate => {
    return {...item};
};

export const toApiOrderCreate = (order: OrderCreate): ApiOrderCreate => {
    return {
        ...order,
        note: order.note || '',
        items: order.items.map(toApiOrderItemCreate),
    };
};
